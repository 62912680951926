export interface Doc {
  id: number;
  name: string;
  ref: string;
  supplier_id: number;
  supplier_name: string;
  stock_id: number;
  stock_name: string;
  doc_date: Date;
  total_qty: string;
}

export interface DocLine {
  id: number;
  line_num: number;
  qty: number;
  doc_id: number;
  product_id: number;
  product_name: string;
  ean: string;
  total_cart_qty: number;
  todo: number;
  scanned: number;
  done: number;
}

export interface DocLineDialog {
  is_open: boolean;
  item: DocLine;
}

export interface searchEan {
  ean: string;
  doc_id: number;
}
export interface MoveCartToLocation {
  ean: string;
  doc_id: number;
}

export interface ScanEan {
  ean: string;
  timeStamp: number;
  mode: ModeOperation;
  type: TypeScanOperation;
}
export interface ScanOperation {
  operation: string;
  timeStamp: number;
  mode: ModeOperation;
  type: TypeScanOperation;
}

export interface ScanLoc {
  loc: string;
  timeStamp: number;
  mode: ModeOperation;
  type: TypeScanOperation;
}

export interface searchCarts {
  doc_id: number;
  ean: string;
}

export interface CartCreate {
  doc_id: number;
  location_name: string;
  partner_id?: number;
  product_id: number;
  qty: number;
}

export enum ProblemNotificationType {
  UrgentProblem = "urgent_problem",
  Problem = "problem",
  LowProblem = "low_problem",
  ProductDamaged = "product_damaged",
  ProductNotDelivered = "product_not_delivered",
}

export interface ProblemNotification {
  doc_id: number;
  line_ids: DocLine[];
  product_problem_total_qty?: number;
  product_problem: string;
  problem_type: ProblemNotificationType;
}

export interface CartUpdate {
  doc_id: number;
  location_name: string;
  stock_id: number;
  partner_id?: number;
  product_id: number;
  qty: number;
  ean: string;
  add_qty_value: boolean;
}
export interface Cart {
  id: number;
  doc_id: number;
  location_name: string;
  location_id: number;
  product_id: number;
  total_carts_qty: number;
  qty: number;
}
export interface Location {
  id: number;
  name: string;
}

export interface LocationLine {
  id?: number;
  cart?: Cart;
  loc?: Location;
  ean?: string;
  line_num: string;
  cart_qty: number;
  product_id: number;
  stock_id: number;
  stored_qty: number;
}

export interface EanLineLoc {
  total_ordered_qty: number;
  total_stored_qty: number;
  total_cart_qty: number;
  total_todo: number;
  date_out?: string;
  lines: LocationLine[];
  direction_name?: string;
  partner_name?: string;
  partner_id?: number;
  order_id?: number;
  order_name?: string;
}

export enum TypeScanOperation {
  Init = "init",
  Loc = "location",
  Ean = "barcode",
  Op = "operation",
  Error = "error",
}
export enum ModeOperation {
  Manual = "manual",
  Scanner = "scanner",
  Init = "init",
}

export interface Scanned {
  value: string;
  type: TypeScanOperation;
  mode: ModeOperation;
}
