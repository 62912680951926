import { mutations } from "@/store/doc/mutations";
import { getters } from "@/store/doc/getters";
import { actions } from "@/store/doc/actions";
import { DocsState } from "@/store/doc/state";
import { ModeOperation, TypeScanOperation } from "@/interfaces/docs";

const defaultState: DocsState = {
  docs: [],
  current_doc: {
    id: 0,
    name: "",
    ref: "",
    supplier_id: 0,
    supplier_name: "",
    stock_id: 0,
    stock_name: "",
    doc_date: new Date(),
    total_qty: "",
  },

  doc_line: {
    id: -1,
    doc_id: -1,
    line_num: -1,
    qty: 0,
    scanned: 0,
    todo: 0,
    done: 0,
    ean: "",
    product_id: 0,
    product_name: "",
    total_cart_qty: 0,
  },
  ean_line_locs: [],
  carts: [],
  barcode: {
    ean: "#############",
    timeStamp: 0,
    mode: ModeOperation.Init,
    type: TypeScanOperation.Init,
  },
  location: {
    loc: "#############",
    timeStamp: 0,
    mode: ModeOperation.Init,
    type: TypeScanOperation.Init,
  },
  operation: {
    operation: "#############",
    timeStamp: 0,
    mode: ModeOperation.Init,
    type: TypeScanOperation.Init,
  },
  doc_lines: [],
  showAllColumn: false,
};

export const docModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
