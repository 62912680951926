import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { DocsState } from "@/store/doc/state";

export const getters = {
  getAllDocs: (state: DocsState) => state.docs,
  getCurrentDoc: (state: DocsState) => state.current_doc,
  getDocLines: (state: DocsState) => state.doc_lines,
  findLineInDocLines: (state: DocsState) => (ean: string) =>
    state.doc_lines.find((l) => {
      return l.ean === ean;
    }),
  getLocation: (state: DocsState) => state.location,
  getBarcode: (state: DocsState) => state.barcode,
  getOperation: (state: DocsState) => state.operation,
  getShowAllColumn: (state: DocsState) => state.showAllColumn,
  getDocEanLine: (state: DocsState) => state.doc_line,
  getEanLineLocs: (state: DocsState) => state.ean_line_locs,
  getCartTotalQTY: (state: DocsState) => (product_id: number, doc_id: number) => {
    const cartByProduct = state.carts.filter((c) => {
      return c.product_id === product_id && c.doc_id === doc_id;
    });

    return cartByProduct.length > 0
      ? cartByProduct.reduce<number>((sum, current) => sum + current.qty, 0)
      : 0;
  },
};

const { read } = getStoreAccessors<DocsState, State>("");

export const readDocs = read(getters.getAllDocs);
export const readCurrentDoc = read(getters.getCurrentDoc);
export const readDocsLines = read(getters.getDocLines);
export const searchLineInDocLines = read(getters.findLineInDocLines);
export const readDocLine = read(getters.getDocEanLine);
export const readEanLineLocs = read(getters.getEanLineLocs);
export const readCartTotalQTY = read(getters.getCartTotalQTY);
export const readBarcode = read(getters.getBarcode);
export const readLocation = read(getters.getLocation);
export const readOperation = read(getters.getOperation);
export const readShowAllColumn = read(getters.getShowAllColumn);
