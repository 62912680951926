import { api } from "@/api";
import { ActionContext } from "vuex";
import { State } from "../state";
import { getStoreAccessors } from "typesafe-vuex";
import { dispatchCheckApiError } from "../main/actions";
import { DocsState } from "@/store/doc/state";
import {
  commitBarcode,
  commitCarts,
  commitCurrentDoc,
  commitDocLines,
  commitDocLine,
  commitEanLineLocs,
  commitLocation,
  commitOperation,
  commitSetDocs,
  commitShowAllColumn,
} from "@/store/doc/mutations";
import {
  CartCreate,
  CartUpdate,
  Doc,
  MoveCartToLocation,
  ProblemNotification,
  ScanEan,
  ScanLoc,
  ScanOperation,
  searchCarts,
  searchEan,
} from "@/interfaces/docs";

type MainContext = ActionContext<DocsState, State>;

export const actions = {
  async actionGetDocs(context: MainContext) {
    try {
      const response = await api.getDocs(context.rootState.main.token);
      if (response) {
        commitSetDocs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateDocFromSource(context: MainContext, doc_id: number) {
    try {
      const response = await api.updateDocFromSource(
        context.rootState.main.token,
        doc_id,
      );
      if (response) {
        // commitSetDocs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetDocById(context: MainContext, doc_id: number) {
    const response = await api.getDocsById(context.rootState.main.token, doc_id);
    if (response) {
      commitCurrentDoc(context, response.data);
    }
  },
  async actionSetCurrentDoc(context: MainContext, payload: Doc) {
    commitCurrentDoc(context, payload);
  },
  async actionSetShowAllColumn(context: MainContext, payload: boolean) {
    commitShowAllColumn(context, payload);
  },
  async actionGetDocLines(context: MainContext, doc_id: number) {
    try {
      const response = await api.getDocLinesByDocs(
        context.rootState.main.token,
        doc_id,
      );
      if (response) {
        commitDocLines(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetDocsLineByEan(context: MainContext, payload: searchEan) {
    const response = await api.getDocLineByEan(
      context.rootState.main.token,
      payload.doc_id,
      payload.ean,
    );
    if (response) {
      commitDocLine(context, response.data);
    }
  },
  async actionGetEanLinesLoc(context: MainContext, payload: searchEan) {
    const response = await api.getEanLinesLoc(
      context.rootState.main.token,
      payload.doc_id,
      payload.ean,
    );
    if (response) {
      commitEanLineLocs(context, response.data);
    }
  },
  async actionGetCartsByEanAndDoc(context: MainContext, payload: searchCarts) {
    const response = await api.getCartsBYEanAndDoc(
      context.rootState.main.token,
      payload.doc_id,
      payload.ean,
    );
    if (response) {
      commitCarts(context, response.data);
    }
  },
  async actionCreateCart(context: MainContext, payload: CartCreate) {
    try {
      const response = await api.createCart(
        context.rootState.main.token,
        payload.doc_id,
        payload,
      );
      if (response) {
        // commitEanLineLocs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateCart(context: MainContext, payload: CartUpdate) {
    try {
      await api.updateCart(context.rootState.main.token, payload.doc_id, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSentProblemNotification(
    context: MainContext,
    payload: ProblemNotification,
  ) {
    try {
      await api.sentProblemNotification(
        context.rootState.main.token,
        payload.doc_id,
        payload,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionMoveALLCartsToLocation(
    context: MainContext,
    payload: MoveCartToLocation,
  ) {
    try {
      const response = await api.moveALLCartsToLocation(
        context.rootState.main.token,
        payload,
      );
      if (response) {
        console.log("Operation, move Cart items to location is: ");
        console.log(response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionSetBarcode(context: MainContext, payload: ScanEan) {
    commitBarcode(context, payload);
  },
  async actionSetLocation(context: MainContext, payload: ScanLoc) {
    commitLocation(context, payload);
  },
  async actionSetOperation(context: MainContext, payload: ScanOperation) {
    commitOperation(context, payload);
  },
};

const { dispatch } = getStoreAccessors<DocsState, State>("");

export const dispatchGetDocs = dispatch(actions.actionGetDocs);
export const dispatchGetDocById = dispatch(actions.actionGetDocById);
export const dispatchSetCurrentDoc = dispatch(actions.actionSetCurrentDoc);
export const dispatchSetShowAllColumn = dispatch(actions.actionSetShowAllColumn);
export const dispatchGetDocLines = dispatch(actions.actionGetDocLines);
export const dispatchGetDocsLineByEan = dispatch(actions.actionGetDocsLineByEan);
export const dispatchGetEanLinesLoc = dispatch(actions.actionGetEanLinesLoc);
export const dispatchGetCartsByEanAndDoc = dispatch(actions.actionGetCartsByEanAndDoc);
export const dispatchCreateCart = dispatch(actions.actionCreateCart);
export const dispatchUpdateCart = dispatch(actions.actionUpdateCart);
export const dispatchSetBarcode = dispatch(actions.actionSetBarcode);
export const dispatchSetLocation = dispatch(actions.actionSetLocation);
export const dispatchSetOperation = dispatch(actions.actionSetOperation);
export const dispatchMoveALLCartsToLocation = dispatch(
  actions.actionMoveALLCartsToLocation,
);
export const dispatchUpdateDocFromSource = dispatch(actions.actionUpdateDocFromSource);
export const dispatchSentProblemNotification = dispatch(
  actions.actionSentProblemNotification,
);
