import { api } from "@/api";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import axios from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetToken,
  commitSetUserProfile,
} from "./mutations";
import { AppNotification, MainState } from "./state";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: "Logged out", color: "success" });
  },

  async actionAddSuccessNotification(context: MainContext, text: string) {
    commitAddNotification(context, { content: text, color: "success" });
  },

  async actionAddWarningNotification(context: MainContext, text: string) {
    commitAddNotification(context, { content: text, color: "warning" });
  },

  async actionAddErrorNotification(context: MainContext, text: string) {
    commitAddNotification(context, { content: text, color: "error" });
  },

  async actionAddPrimaryNotification(context: MainContext, text: string) {
    commitAddNotification(context, { content: text, color: "primary", centred: true });
  },

  actionRouteLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        // await dispatchLogOut(context);
      } else if (payload.response?.status === 500) {
        console.log("Full error:", payload);
        commitAddNotification(context, {
          color: "error",
          content: `${payload.response?.data}`,
          centred: true,
        });
      } else if (payload.response?.status === 404) {
        console.log("404", payload);
        console.log("current route", router.currentRoute);
        commitAddNotification(context, {
          color: "error",
          showProgress: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          content: `${payload.response?.data.detail}`,
          centred: true,
        });
        if (router.currentRoute.name === "main-documents-ean-lines") {
          console.log(" back to Doc");
          await router.push({
            name: "main-documents-ean",
            params: { doc_id: router.currentRoute.params.doc_id },
          });
          return;
        }
        if (router.currentRoute.name === "main-documents-ean") {
          console.log(" back to Doc List");
          await router.push({ name: "main-documents-list" });
          return;
        }
      }
    }
  },
  actionRouteLoggedIn() {
    if (router.currentRoute.path === "/login" || router.currentRoute.path === "/") {
      router.push("/main");
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        api.passwordRecovery(payload.username),
        await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { color: "error", content: "Incorrect username" });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingNotification = { content: "Resetting password", showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        api.resetPassword(payload.password, payload.token),
        await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchErrorNotification = dispatch(actions.actionAddErrorNotification);
export const dispatchWarningNotification = dispatch(
  actions.actionAddWarningNotification,
);
export const dispatchSuccessNotification = dispatch(
  actions.actionAddSuccessNotification,
);
export const dispatchSuccessPrimary = dispatch(actions.actionAddPrimaryNotification);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
