import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import {
  Cart,
  Doc,
  DocLine,
  EanLineLoc,
  ScanLoc,
  ScanEan,
  ScanOperation,
} from "@/interfaces/docs";
import { DocsState } from "@/store/doc/state";

export const mutations = {
  setDocs(state: DocsState, payload: Doc[]) {
    state.docs = payload;
  },
  setCurrentDoc(state: DocsState, payload: Doc) {
    state.current_doc = payload;
  },
  setDocLines(state: DocsState, payload: DocLine[]) {
    state.doc_lines = payload;
  },
  setShowAllColumn(state: DocsState, payload: boolean) {
    state.showAllColumn = payload;
  },
  setDocLine(state: DocsState, payload: DocLine) {
    state.doc_line = payload;
  },
  setBarcode(state: DocsState, payload: ScanEan) {
    state.barcode = payload;
  },
  setLocation(state: DocsState, payload: ScanLoc) {
    state.location = payload;
  },
  setOperation(state: DocsState, payload: ScanOperation) {
    state.operation = payload;
  },
  setEanLineLocs(state: DocsState, payload: EanLineLoc[]) {
    state.ean_line_locs = payload;
  },
  setCarts(state: DocsState, payload: Cart[]) {
    state.carts = payload;
  },
};

const { commit } = getStoreAccessors<DocsState, State>("");

export const commitSetDocs = commit(mutations.setDocs);
export const commitDocLines = commit(mutations.setDocLines);
export const commitDocLine = commit(mutations.setDocLine);
export const commitEanLineLocs = commit(mutations.setEanLineLocs);
export const commitCarts = commit(mutations.setCarts);
export const commitBarcode = commit(mutations.setBarcode);
export const commitLocation = commit(mutations.setLocation);
export const commitOperation = commit(mutations.setOperation);
export const commitCurrentDoc = commit(mutations.setCurrentDoc);
export const commitShowAllColumn = commit(mutations.setShowAllColumn);
