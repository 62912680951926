import axios from "axios";
import { apiUrl } from "@/env";
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from "./interfaces";
import {
  Doc,
  DocLine,
  EanLineLoc,
  Cart,
  CartCreate,
  CartUpdate,
  MoveCartToLocation,
  ProblemNotification,
} from "@/interfaces/docs";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async getDocs(token: string) {
    return axios.get<Doc[]>(`${apiUrl}/api/v1/docs/`, authHeaders(token));
  },
  async getDocsById(token: string, docId: number) {
    return axios.get<Doc>(`${apiUrl}/api/v1/docs/${docId}`, authHeaders(token));
  },
  async updateDocFromSource(token: string, docId: number) {
    return axios.post<boolean>(
      `${apiUrl}/api/v1/docs/${docId}/update_from_source`,
      {},
      authHeaders(token),
    );
  },
  async getDocLineByEan(token: string, docId: number, ean: string) {
    return axios.get<DocLine>(
      `${apiUrl}/api/v1/docs/${docId}/${ean}`,
      authHeaders(token),
    );
  },
  async getDocLinesByDocs(token: string, docId: number) {
    return axios.get<DocLine[]>(
      `${apiUrl}/api/v1/docs/${docId}/lines`,
      authHeaders(token),
    );
  },
  async sentProblemNotification(
    token: string,
    docId: number,
    data: ProblemNotification,
  ) {
    console.log("data problem", data);
    return axios.post<boolean>(
      `${apiUrl}/api/v1/docs/${docId}/sent_problem_line`,
      data,
      authHeaders(token),
    );
  },
  async getEanLinesLoc(token: string, docId: number, ean: string) {
    return axios.get<EanLineLoc[]>(
      `${apiUrl}/api/v1/docs/${docId}/${ean}/ean_loc_lines`,
      authHeaders(token),
    );
  },
  async getCartsBYEanAndDoc(token: string, docId: number, ean: string) {
    return axios.get<Cart[]>(
      `${apiUrl}/api/v1/docs/${docId}/carts/${ean}/list`,
      authHeaders(token),
    );
  },
  async createCart(token: string, docId: number, data: CartCreate) {
    return axios.post<Cart>(
      `${apiUrl}/api/v1/docs/${docId}/carts/create`,
      data,
      authHeaders(token),
    );
  },
  async updateCart(token: string, docId: number, data: CartUpdate) {
    return axios.post<Cart>(
      `${apiUrl}/api/v1/docs/${docId}/carts/update`,
      data,
      authHeaders(token),
    );
  },
  async moveALLCartsToLocation(token: string, data: MoveCartToLocation) {
    return axios.post<boolean>(
      `${apiUrl}/api/v1/docs/${data.doc_id}/carts/move_to_loc`,
      data,
      authHeaders(token),
    );
  },

  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
};
